<template>
  <section class="memberlist-wrapper">
    <card title="会员列表">
      <el-table :data="memberList" border>
        <el-table-column
          label="电话"
          align="center"
          prop="tel"
          :formatter="basicFormmater"
        ></el-table-column>
        <el-table-column
          label="姓名"
          align="center"
          prop="uname"
          :formatter="basicFormmater"
        ></el-table-column>

        <el-table-column
          label="宝宝生日"
          align="center"
          prop="age"
          :formatter="birthdayFormmater"
        ></el-table-column>
        <el-table-column
          label="地区"
          align="center"
          prop="area"
          :formatter="areaFormmater"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="110">
          <template slot-scope="scope">
            <el-button size="mini" @click="viewDetails(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="PageSize"
          layout="total, prev, pager, next,jumper"
          :total="totalCount"
          style="margin: 20px 0 0 30px;"
        >
        </el-pagination>
      </el-table>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      memberList: [],
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0
    };
  },

  methods: {
    viewDetails(index, row) {
      this.$router.push({
        name: "memberorder",
        query: {
          uid: row.uid
        }
      });
    },
    getMemberList() {
      this.$axios({
        url: "/back/user/getAllUser",
        params: {
          page: this.currentPage
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.PageSize = res.data.data.pageSize;
          this.totalCount = res.data.data.total;
          this.memberList = res.data.data.list;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
      });
    },
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getMemberList();
    },
    birthdayFormmater(val) {
      const bir = new Date(val.birthday);
      return val.birthday
        ? bir.getFullYear() +
            "年" +
            (bir.getMonth() + 1) +
            "月" +
            bir.getDate() +
            "日"
        : "未填写";
    },
    basicFormmater(row, col) {
      return row[col.property] ? row[col.property] : "未填写";
    },
    areaFormmater(val) {
      return (
        (val.address.province ? val.address.province + "省" : "") +
        (val.address.city ? val.address.city + "市" : "未填写")
      );
    }
  },
  created() {
    this.$openLoading();
    Promise.all([this.getMemberList()]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style></style>
